import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Loader from '../../Components/Loader/Loader';
import './Auth.css';

import { serviceConsumer } from '../../network/ServiceConsumer';
import { SIGNIN } from '../../redux/actionTypes/authActionType';

import feisLogo from '../../Assets/feis-logo.svg';
import Help from '../../Components/Help/Help';
import Button from '../../Components/Button/Button';
import { ToastContainer, toast } from 'react-toastify';
import { ShowToastMessage } from '../../helper/ShowToastMessage';
import { setLocalForOrganizerAuth } from '../../helper/setLocalStorage';
import { emailValidation, loggedInPwdValidation } from '../../helper/Validations';

const LoginOrg = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const headerClickHandler = () => {
    navigate('/');
  };

  const redirectToForgotpwdHandler = () => {
    navigate('/forgotpwd');
  };

  //Initial values for formik
  const loginInitialValues = {
    email: '',
    password: '',
  };

  //Validation Schema for Formik
  const loginValidationSchema = Yup.object().shape({
    email: emailValidation,
    password: loggedInPwdValidation,
  });

  const loginHandler = async (values) => {
    try {
      setLoading(true);
      let value = { ...values, email: values.email.toLowerCase() };
      const url = `${process.env.REACT_APP_BASE_URL}/api/admin/login`
      const response = await serviceConsumer('POST', url, value);
      if (response.success) {
        setLocalForOrganizerAuth(response)
        dispatch({
          type: SIGNIN,
          payload: response,
        });
        navigate('/', { replace: true });

      }
      setLoading(false);
    } catch (error) {
      toast.dismiss();
      ShowToastMessage("error", error.messages)
      setLoading(false);
    }
  };
  const changeHandler = (e, name, setFieldValue) => {
    setFieldValue(name, e.target.value);
  };

  return (
    <div className="d-flex flex-column justify-content-between p-4 auth-org" style={{ minHeight: '100vh' }}>
      {loading && <Loader />}
      <div>
        <img src={feisLogo} alt="feis-logo" onClick={headerClickHandler} style={{ cursor: 'pointer', width: '136px' }} />
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <p className="auth-title-org">Log in</p>
        <Formik initialValues={loginInitialValues} validationSchema={loginValidationSchema} onSubmit={loginHandler}>
          {({ setFieldValue }) => {
            return (
              <Form autoComplete="off">
                <div>
                  <div className="d-flex flex-column">
                    <label className="label-head">Email:</label>
                    <Field className="input-box" type="email" name="email" placeholder="Email" onChange={(e) => { changeHandler(e, 'email', setFieldValue); }} />
                    <ErrorMessage className="auth-error" name="email" component="div" />
                  </div>
                  <div className="d-flex flex-column ">
                    <label className="label-head">Password: </label>
                    <Field className="input-box" type="password" name="password" placeholder="Password" onChange={(e) => { changeHandler(e, 'password', setFieldValue); }} />
                    <ErrorMessage className="auth-error" name="password" component="div" />
                  </div>
                  <p className="text-button mt-3" onClick={redirectToForgotpwdHandler} > I forgot my password </p>
                  <div className="d-flex flex-grow-1 justify-content-center mt-3">
                    <Button className="filled">Log in</Button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      <Help />
      <ToastContainer />
    </div>
  );
};

export default LoginOrg;
