import React from 'react'
import DatePicker from 'react-datepicker';
import { Field, ErrorMessage, } from 'formik';
import { addYears } from 'date-fns';
import { handleWheel } from '../../../../helper/handleActions';
import moment from 'moment';


const handleKeyDown = (e) => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
        e.preventDefault();
        e.currentTarget.blur(); // Remove focus from the input field
    }
};

const getClassName = (errors, touched, errorClass, defaultClass) => {
    if (errors && touched) return errorClass
    else return defaultClass
}

const EarlyAndLateFee = ({ formik }) => {
    const maxDate = addYears(new Date(), 100);
    return (
        <>
            <div>
                <h3 className="EventDateOrg">Early and Late Fees</h3>
            </div>
            <span className="EventHeader-org">
                <h4>Early Fee</h4>
            </span>
            {renderFamLessAndAdditional(formik, "familyless", formik.errors.familyless, formik.touched.familyless)}
            <div className={getClassName(formik.errors.ifDateIsBefore, formik.touched.ifDateIsBefore, '', 'input-control')}>
                <label
                    className={getClassName(formik.errors.ifDateIsBefore, formik.touched.ifDateIsBefore, 'label-head-org-error', 'label-head-org')}
                >
                    If date is before:
                </label>
                <br />
                <Field name="ifDateIsBefore">
                    {({ field, form }) => (
                        <DatePicker
                            className={getClassName(formik.errors.ifDateIsBefore, formik.touched.ifDateIsBefore, 'input-box-org-error', 'input-box-org')}
                            selected={field.value}
                            onChange={date => form.setFieldValue('ifDateIsBefore', date)}
                            onBlur={() => formik.setFieldTouched('ifDateIsBefore', true)}
                            minDate={new Date()}
                            maxDate={maxDate}
                            placeholderText="MM/DD/YYYY"
                        />
                    )}
                </Field>
                <ErrorMessage className="auth-error" name="ifDateIsBefore" component="div" />{' '}
            </div>
            <span className="EventHeader-org" style={{ paddingTop: '16px' }}>
                <h4>Late fee</h4>
            </span>
            {renderFamLessAndAdditional(formik, "additionfee", formik.errors.additionfee, formik.touched.additionfee)}
            <div className={getClassName(formik.errors.afterdate, formik.touched.afterdate, '', 'input-control')}>
                <label
                    className={getClassName(formik?.errors?.afterdate, formik?.touched?.afterdate, 'label-head-org-error', 'label-head-org')}
                >
                    After Date:
                </label>
                <br />
                <Field name="afterdate">
                    {({ field, form }) => (
                        <DatePicker
                            className={getClassName(formik.errors.afterdate, formik.touched.afterdate, 'input-box-org-error', 'input-box-org')}
                            selected={field.value}
                            onChange={(date) => {
                                form.setFieldValue('afterdate', date);
                                formik.setFieldValue('afterdate', date);
                                formik.setFieldTouched('afterdate', true);
                                setTimeout(() => { formik.validateForm(); }, 0);
                            }}
                            onBlur={() => formik.setFieldTouched('afterdate', true)}
                            minDate={new Date()}
                            maxDate={maxDate}
                            placeholderText="MM/DD/YYYY"

                        />
                    )}
                </Field>
                <ErrorMessage className="auth-error" name="afterdate" component="div" />{' '}
            </div>
            <span className="EventHeader-org" style={{ paddingTop: '16px' }}>
                <h4>Late fee After Closed Registration</h4>
            </span>
            <div className={getClassName(formik.errors.lateadditionfee, formik.touched.lateadditionfee, '', 'input-control')}>
                <label
                    className={getClassName(formik.errors.lateadditionfee, formik.touched.lateadditionfee, 'label-head-org-error', 'label-head-org')}
                >
                    Additional:{' '}
                </label>
                <br />
                <Field
                    className={getClassName(formik.errors.lateadditionfee, formik.touched.lateadditionfee, 'input-box-org-error', 'input-box-org')}
                    type="number"
                    name="lateadditionfee"
                    placeholder="Additional"
                    step="any"
                    onWheel={handleWheel}
                    onKeyDown={handleKeyDown}
                    onKeyPress={(e) => {
                        if (e.key === 'e') {
                            e.preventDefault();
                        }
                    }}

                />
                <ErrorMessage className="auth-error" name="lateadditionfee" component="div" />
            </div>
            <div>
                <label
                    className={getClassName(formik.errors.lateafterdate, formik.touched.lateafterdate, 'label-head-org-error', 'label-head-org')}
                >
                    After Date:{' '}
                </label>
                <br />
                <Field name="lateafterdate">
                    {({ field, form }) => (
                        <DatePicker
                            readOnly
                            className={getClassName(formik.errors.lateafterdate, formik.touched.lateafterdate, 'input-box-org-error', 'input-box-org')}
                            // selected={field.value}
                            selected={field?.value && moment(field?.value).add(1, 'days').toDate()}
                            onChange={(date) => { form.setFieldValue('lateafterdate', date); }}
                            onBlur={() => { formik.setFieldTouched('lateafterdate', true); }}
                            minDate={new Date()}
                            maxDate={maxDate}
                            placeholderText="MM/DD/YYYY"
                            disabled
                        />
                    )}
                </Field>
            </div>
        </>
    )
}

export default EarlyAndLateFee

const renderFamLessAndAdditional = (formik, name, errorValidation, touchValidation) => {
    return <div className={errorValidation && touchValidation ? '' : 'input-control'}>
        <label className={errorValidation && touchValidation ? 'label-head-org-error' : 'label-head-org'}>
            {name === "additionfee" ? "Additional:" : "Family Less:"}
        </label>
        <br />
        <Field
            className={errorValidation && touchValidation ? 'input-box-org-error' : 'input-box-org'}
            type="number"
            name={name}
            onWheel={handleWheel}
            onKeyDown={handleKeyDown}
            placeholder={name === "additionfee" ? "Additional" : "Family fee"}
            step="any"
            onKeyPress={(e) => { if (e.key === 'e') { e.preventDefault(); } }}
        />
        <ErrorMessage className="auth-error" name={name} component="div" />{' '}
    </div>
}